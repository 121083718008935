import React, { useMemo, useRef } from 'react';
import { useQuery } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text, Stack, xcss } from '@atlaskit/primitives';

import type { ResolvedInlineCommentsQueryType } from '@confluence/inline-comments-queries';
import { ResolvedInlineCommentsQuery } from '@confluence/inline-comments-queries';
import type { CommentData } from '@confluence/comments-data';
import { updateCommentsDataState, useCommentsData } from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useSessionData } from '@confluence/session-data';
import { useEditorAnnotations } from '@confluence/inline-comments-hooks';
import type { FlagsStateContainer } from '@confluence/flags';

import { CommentsPanelList } from '../components/CommentsPanelList';
import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';

type ResolvedViewProps = {
	flags?: FlagsStateContainer;
};

const emptyContainerStyles = xcss({
	alignItems: 'center',
	marginTop: 'space.600',
});

const i18n = defineMessages({
	noResolvedCommentsText: {
		id: 'comments-panel.resolvedview.empty.text',
		defaultMessage: 'There are no resolved comments.',
		description:
			'Text to display in Resolved View when there are no resolved comments for the page.',
	},
});

const getResolvedCommentThreads = ({
	inlineCommentsDataMap,
}: {
	inlineCommentsDataMap: Record<string, CommentData>;
}) => {
	const resolvedCommentThreads: CommentData[] = [];
	for (const key in inlineCommentsDataMap) {
		const comment = inlineCommentsDataMap[key];
		if (comment.isOpen === false) {
			resolvedCommentThreads.push(comment);
		}
	}
	return resolvedCommentThreads;
};

export const ResolvedView = ({ flags }: ResolvedViewProps) => {
	const [contentId] = usePageContentId();
	const { formatMessage } = useIntl();
	const [{ inlineCommentsDataMap }, { addNewCommentThreads }] = useCommentsData();
	const [{ readCommentsListState }] = useUnreadInlineComments();
	const [{ annotations }] = useEditorAnnotations();
	const pageMode = useGetPageMode();
	const { userId: currentUserId } = useSessionData();
	const hasAlreadyFetchedInitialData = useRef(false);

	const annotationsInEditorDoc = useMemo(() => {
		return new Set(annotations);
	}, [annotations]);

	const { loading, error, refetch } = useQuery<ResolvedInlineCommentsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolvedInlineCommentsQuery,
		{
			variables: {
				pageId: contentId,
			},
			onCompleted: (data) => {
				updateCommentsDataState({
					data,
					readCommentsListState,
					addNewCommentThreads,
					annotationsInEditorDoc,
					pageMode,
					currentUserId: currentUserId ?? '',
					hasAlreadyFetchedInitialData: true, // true since we already fetched open comments and are now just adding resolved comments
					setInlineCommentsDataMap: () => {},
					isOpen: false,
				});

				hasAlreadyFetchedInitialData.current = true;
			},
		},
	);

	const resolvedComments = useMemo(
		() => getResolvedCommentThreads({ inlineCommentsDataMap }),
		[inlineCommentsDataMap],
	);

	if (error) {
		return (
			<ErrorPanel
				error={error}
				onRetryClick={async () => {
					await refetch();
				}}
			/>
		);
	}

	return (
		<>
			{!hasAlreadyFetchedInitialData.current && loading ? (
				<Loading />
			) : (
				<>
					{resolvedComments.length > 0 ? (
						<CommentsPanelList
							commentThreads={resolvedComments}
							supportedTopLevelActions={['reopen']}
							flags={flags}
						/>
					) : (
						<Stack xcss={emptyContainerStyles} testId="resolvedview-empty">
							<Text color="color.text.subtle">{formatMessage(i18n.noResolvedCommentsText)}</Text>
						</Stack>
					)}
				</>
			)}
		</>
	);
};
