import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { CommentData } from '@confluence/comments-data';
import type { FlagsStateContainer } from '@confluence/flags';

import { CommentThread } from './CommentThread';

type CommentPanelListProps = {
	commentThreads: CommentData[];
	supportedTopLevelActions: CommentAction[];
	flags?: FlagsStateContainer;
};

const listStyles = xcss({
	overflowY: 'auto',
	overflowX: 'hidden',
});

// this component holds a list of comments
export const CommentsPanelList = ({
	commentThreads,
	supportedTopLevelActions,
	flags,
}: CommentPanelListProps) => {
	const pageMode = useGetPageMode();
	const eventEmitter =
		pageMode === PageMode.EDIT || pageMode === PageMode.LIVE
			? getEditorAnnotationEventEmitter()
			: getRendererAnnotationEventEmitter();

	return (
		<Stack xcss={listStyles} testId="comments-panel-list-container">
			{commentThreads.map((parentComment) => (
				<CommentThread
					parentComment={parentComment}
					supportedTopLevelActions={supportedTopLevelActions}
					eventEmitter={eventEmitter}
					key={parentComment.id}
					pageMode={pageMode}
					flags={flags}
				/>
			))}
		</Stack>
	);
};
