import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { N20A, N30, N500, B50, B400 } from '@atlaskit/theme/colors';

import type { CurrentView } from '../hooks/useCommentsPanel';

const pillsContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingBottom: 'space.150',
	paddingLeft: 'space.300',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-html-button -- To migrate as part of go/ui-styling-standard
const PillButton = styled.button<{
	isSelected?: boolean;
}>({
	outline: 'none',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isSelected
			? token('color.background.selected', B50)
			: token('color.background.neutral', N20A),
	display: 'inline-flex',
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isSelected ? token('color.text.selected', B400) : token('color.text', N500),
	cursor: 'pointer',
	userSelect: 'none',
	whiteSpace: 'nowrap',
	verticalAlign: 'top',
	marginLeft: token('space.100', '8px'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: (props) =>
			props.isSelected
				? token('color.background.selected.hovered', B50)
				: token('color.background.neutral.hovered', N30),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: token('space.100', '8px'),
	// TODO: Change B400 here to B700 when it becomes available
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: (props) =>
		props.isSelected
			? `1px solid ${token('color.border.focused', B400)}`
			: `1px solid ${token('color.background.neutral', N20A)}`,
	borderRadius: '100px',
	height: '32px',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: '48px',
	'&:focus': {
		outline: `2px solid ${token('color.border.focused', B400)}`,
		borderColor: 'transparent',
	},
});

export type PillFilter = {
	label: {
		id: string;
		defaultMessage: string;
		description: string;
	};
	name: CurrentView;
};

export type PillProps = {
	selectedPill?: CurrentView;
	setPill?: (view: CurrentView) => void;
	pills?: PillFilter[];
	additionalActions?: ((...args: any[]) => void)[];
};

export const Pills: FC<PillProps> = ({ selectedPill, setPill, pills, additionalActions }) => {
	const { formatMessage } = useIntl();

	const onClick = (pillButton: PillFilter) => {
		setPill && setPill(pillButton.name);

		if (additionalActions) {
			additionalActions.forEach((action) => action());
		}
	};

	return pills ? (
		<Flex role="tablist" xcss={pillsContainerStyles}>
			{pills.map((pillButton) => (
				<PillButton
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="select-task-view"
					key={pillButton.name}
					isSelected={pillButton.name === selectedPill}
					onClick={() => onClick(pillButton)}
					role="tab"
					aria-selected={pillButton.name === selectedPill}
				>
					{formatMessage(pillButton.label)}
				</PillButton>
			))}
		</Flex>
	) : null;
};
