import type {
	ActiveInlineCommentsQueryType,
	CommentLocation,
	CommentReply,
} from '@confluence/inline-comments-queries';
import { isValidUnreadComment } from '@confluence/unread-comments';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';

import type { AnnotationStatus, CommentData } from '../useCommentsData';

export const updateCommentsDataState = ({
	data,
	readCommentsListState,
	addNewCommentThreads,
	annotationsInEditorDoc,
	pageMode,
	currentUserId,
	setInlineCommentsDataMap,
	hasAlreadyFetchedInitialData,
	isOpen = true,
}: {
	data: ActiveInlineCommentsQueryType;
	readCommentsListState: Set<string>;
	addNewCommentThreads: (newCommentThreads: Record<string, CommentData>) => void;
	annotationsInEditorDoc: Set<string>;
	pageMode: PageMode;
	currentUserId: string;
	setInlineCommentsDataMap: (newCommentsDataMap: Record<string, CommentData>) => void;
	hasAlreadyFetchedInitialData: boolean;
	isOpen: boolean;
}) => {
	const commentThreadsToAdd: Record<string, CommentData> = {};

	data?.comments?.nodes?.forEach((comment) => {
		if (comment) {
			const markerRef = (comment.location as CommentLocation).inlineMarkerRef;
			if (markerRef) {
				commentThreadsToAdd[markerRef] = {
					...comment,
					isUnread: !!isValidUnreadComment({
						comment,
						viewedComments: readCommentsListState,
						userId: currentUserId,
						annotationsInEditorDoc,
						pageMode: pageMode as 'view' | 'edit',
					}),
					isOpen,
					replies: (comment.replies as CommentReply[]).map((reply) => ({
						...reply,
						isUnread: !!isValidUnreadComment({
							comment: reply,
							viewedComments: readCommentsListState,
							userId: currentUserId,
							annotationsInEditorDoc,
							pageMode: pageMode as 'view' | 'edit',
						}),
					})),
				};
			}
		}
	});

	if (hasAlreadyFetchedInitialData) {
		// if we've already fetched initidal data, then just add the comment thread to the data store
		addNewCommentThreads(commentThreadsToAdd);
	} else {
		// if we haven't fetched initial data yet, then just set the data store directly
		setInlineCommentsDataMap(commentThreadsToAdd);
	}
};

export const getAnnotationPosition = (
	annotationId: string,
	annotations: AnnotationStatus[],
): number => {
	return annotations.findIndex((annotation) => annotation.annotationId === annotationId);
};
