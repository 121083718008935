import { useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { ActiveInlineCommentsQuery } from '@confluence/inline-comments-queries';
import type {
	ActiveInlineCommentsQueryType,
	ActiveInlineCommentsQueryVariables,
} from '@confluence/inline-comments-queries';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useEditorAnnotations } from '@confluence/inline-comments-hooks';
import { useSessionData } from '@confluence/session-data';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';

import { useCommentsData, type AnnotationStatus } from '../useCommentsData';
import { updateCommentsDataState } from '../helper/commentsDataHelper';

export const COMMENT_BATCH_SIZE = 1000; // setting to 1000 for now until we implement infinite scroll for the comments panel

// gets comments using paginated query
export const useActiveInlineCommentsQuery = ({
	pageId,
	markerRefList,
	orderedActiveAnnotationIdList,
	skip = false,
	batchSize,
	hasAlreadyFetchedInitialData,
}: {
	pageId: string;
	markerRefList: string[];
	orderedActiveAnnotationIdList: AnnotationStatus[];
	skip?: boolean;
	batchSize?: number;
	hasAlreadyFetchedInitialData?: React.MutableRefObject<boolean>;
}) => {
	const [, { addNewCommentThreads, setInlineCommentsDataMap }] = useCommentsData();

	const [{ readCommentsListState }] = useUnreadInlineComments();

	const [{ annotations }] = useEditorAnnotations();
	const pageMode = useGetPageMode();
	const { userId: currentUserId } = useSessionData();

	const [startOffset, setStartOffset] = useState(0);
	const [endOffset, setEndOffset] = useState(COMMENT_BATCH_SIZE);

	// Determine the batch size to use
	const numParentCommentsToFetch = batchSize || COMMENT_BATCH_SIZE;

	// Determine if the query should be skipped
	const skipQuery = !pageId || markerRefList.length === 0 || skip;

	// Determine the subset of markers to fetch based on offsets
	const currentMarkerRefList = useMemo(() => {
		return markerRefList.slice(startOffset, endOffset);
	}, [markerRefList, startOffset, endOffset]);

	const annotationsInEditorDoc = useMemo(() => {
		return new Set(annotations);
	}, [annotations]);

	// Execute the query
	const {
		data,
		loading: isLoading,
		error,
		refetch,
	} = useQuery<ActiveInlineCommentsQueryType, ActiveInlineCommentsQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ActiveInlineCommentsQuery,
		{
			variables: {
				pageId,
				inlineMarkerRefList: currentMarkerRefList,
			},
			fetchPolicy: 'network-only', // we always want to fetch the latest data
			skip: skipQuery,
			onCompleted: (data: ActiveInlineCommentsQueryType) => {
				updateCommentsDataState({
					data,
					readCommentsListState,
					addNewCommentThreads,
					annotationsInEditorDoc,
					pageMode,
					currentUserId: currentUserId ?? '',
					hasAlreadyFetchedInitialData: !!hasAlreadyFetchedInitialData?.current,
					setInlineCommentsDataMap,
					isOpen: true,
				});
				if (hasAlreadyFetchedInitialData && !hasAlreadyFetchedInitialData.current) {
					hasAlreadyFetchedInitialData.current = true;
				}
			},
		},
	);

	// Function to fetch more annotations before the current annotation
	const fetchMoreAnnotationsBefore = useCallback(async () => {
		if (!isLoading) {
			setStartOffset((prevOffset) => Math.max(0, prevOffset - numParentCommentsToFetch));
			await refetch();
		}
	}, [refetch, numParentCommentsToFetch, isLoading]);

	// Function to fetch more annotations after the current annotation
	const fetchMoreAnnotationsAfter = useCallback(async () => {
		if (!isLoading) {
			setEndOffset((prevOffset) =>
				Math.min(orderedActiveAnnotationIdList.length, prevOffset + numParentCommentsToFetch),
			);
			await refetch();
		}
	}, [refetch, numParentCommentsToFetch, orderedActiveAnnotationIdList.length, isLoading]);

	return {
		isLoading,
		data,
		error,
		fetchMoreAnnotationsBefore,
		fetchMoreAnnotationsAfter,
		refetch,
	};
};
