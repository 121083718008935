import React, { useRef } from 'react';

import {
	useActiveInlineCommentsQuery,
	useCommentsData,
	COMMENT_BATCH_SIZE,
} from '@confluence/comments-data';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { usePageContentId } from '@confluence/page-context';

import { CommentsPanel } from './CommentsPanel';
import { CurrentView } from './hooks/useCommentsPanel';
import { getAnnotationsToLoad } from './helper/commentsPanelHelper';

// NOTE: this is a temporary component to get comment data just to show our comment list
// The code here can be copied to where we'd use actually want to use it for performance optimization
export const CommentsPanelWrapper = () => {
	const [{ orderedActiveAnnotationIdList }] = useCommentsData();
	const [contentId] = usePageContentId();

	// since we're displaying unread first, we want to get data for the first X # of unread comments
	// scrolling near this threshold will fetch more unread comments after it
	// when you select the Open tab, we'll want to show the first X # of open comments
	// some of these are already loaded so they won't need to be refetched
	// and some of these might be unread comment threads
	// scrolling through the Open tab will fetch more unloaded comments

	const [{ unreadCommentsListState, shouldUpdateUnreadCommentsOnFirstRender }] =
		useUnreadInlineComments();

	const unreadCommentsMarkerRefs =
		unreadCommentsListState
			.map((unreadComment) => unreadComment.inlineMarkerRef)
			.slice(0, Math.min(unreadCommentsListState.length, COMMENT_BATCH_SIZE)) || [];

	const unreadCommentsCallCompleted = !shouldUpdateUnreadCommentsOnFirstRender;

	// we only want to run this code once before the comments panel is first open, then we'll handle actions inside the comments panel component and via pubsub
	const hasAlreadyFetchedInitialData = useRef(false);

	useActiveInlineCommentsQuery({
		pageId: contentId!,
		markerRefList:
			unreadCommentsListState.length > 0
				? unreadCommentsMarkerRefs
				: getAnnotationsToLoad(orderedActiveAnnotationIdList),
		// skip entirely if no unresolved comments on the page
		// if there are annotations, then wait for unread comments call to finish before either fetching unread or open comments
		skip:
			hasAlreadyFetchedInitialData.current ||
			orderedActiveAnnotationIdList.length === 0 ||
			!unreadCommentsCallCompleted,
		orderedActiveAnnotationIdList,
		hasAlreadyFetchedInitialData,
	});

	const currentView = useRef<CurrentView>(CurrentView.UNREAD);

	// if there are no unread comments, then we'll show the open tab
	if (unreadCommentsCallCompleted && unreadCommentsListState.length === 0) {
		currentView.current = CurrentView.OPEN;
	}

	return <CommentsPanel currentView={currentView.current} contentId={contentId ?? ''} />;
};
