import React, { useState, useContext, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';
import { token } from '@atlaskit/tokens';
import { xcss, Box, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AnnotationInfo } from '@atlaskit/editor-plugin-annotation';

import { CommentEditor } from '@confluence/comment';
import { NewReplyContainer } from '@confluence/inline-comments-common/entry-points/styled';
import type {
	InlineCommentReply,
	NewReplyVars,
	CommentLocation,
} from '@confluence/inline-comments-queries';
import { CreateInlineReplyMutation } from '@confluence/inline-comments-queries';
import {
	useCommentsData,
	type CommentData,
	type ReplyData,
	CommentActionType,
} from '@confluence/comments-data';
import { useCommentContentDispatchContext } from '@confluence/comment-context';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import {
	REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import type { PageInfoNode } from '@confluence/page-info';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';

import { onSaveComment, ViewReplyOptions } from '../helper/commentThreadHelper';
import { useCommentsPanel } from '../hooks/useCommentsPanel';

import { Comment } from './Comment';
import { RepliesOpener } from './RepliesOpener';

const replyListStyles = xcss({
	paddingInlineStart: 'space.0',
	marginLeft: 'space.400',
	borderLeft: `2px solid ${token('color.border')}`,
});

const commentBodyBoxStyles = xcss({
	listStyle: 'none',
	position: 'relative',
});

type CommentRepliesProps = {
	parentComment: CommentData;
	allReplies: ReplyData[];
	setHoveredCommentId: (commentId: string | undefined) => void;
	unreadReplies: ReplyData[];
	pageInfo: PageInfoNode | null;
	isCurrentSelectedComment: boolean;
	hoveredCommentId: string;
	pageMode: PageMode;
	editCommentQueryId: string;
	selectedAnnotation: AnnotationInfo;
	parentCommentId: string;
	handleMouseEnter: (id?: string) => void;
	handleMouseLeave: () => void;
	commentThreadSelected: React.MutableRefObject<boolean>;
};

export const CommentReplies = ({
	parentComment,
	allReplies,
	unreadReplies,
	pageInfo,
	isCurrentSelectedComment,
	hoveredCommentId,
	pageMode,
	editCommentQueryId,
	selectedAnnotation,
	parentCommentId,
	handleMouseEnter,
	handleMouseLeave,
	commentThreadSelected,
}: CommentRepliesProps) => {
	const [createReplyFn] = useMutation<
		{ replyInlineComment: InlineCommentReply },
		{ input: NewReplyVars }
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateInlineReplyMutation,
	);
	const { onChange } = useCommentContentDispatchContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ removedCommentsAnnotationMap }, { addReplyToCommentThread }] = useCommentsData();
	const [, { updateReadCommentsListState }] = useUnreadInlineComments();
	const [, { setCommentIdsByAnnotationToMarkAsRead }] = useCommentsPanel();

	const [repliesToDisplay, setRepliesToDisplay] = useState<ReplyData[]>([]);
	const [, setCommentForEdit] = useState('');
	const [currentReplyView, setReplyView] = useState(
		unreadReplies.length > 0 ? ViewReplyOptions.DEFAULT : ViewReplyOptions.HIDDEN,
	);
	const unreadRepliesAlreadyMarkedAsRead = useRef(false);

	const experienceTracker = useContext(ExperienceTrackerContext);

	const pageId = pageInfo?.id ?? '';
	const pageType = pageInfo?.type ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	const isParentCommentOpen = parentComment.isOpen;
	const parentCommentMarkerRef = (parentComment?.location as CommentLocation).inlineMarkerRef || '';
	const supportedTopLevelActions: CommentAction[] = isParentCommentOpen
		? ['edit', 'resolve', 'delete']
		: [];

	if (commentThreadSelected.current && !unreadRepliesAlreadyMarkedAsRead.current) {
		// If the comment thread is selected, we should mark all displayed unread comment ids in it as read
		// don't mark the unread replies that aren't displayed as read since the user will need to expand the reply section to see them
		const idsToMarkAsRead: string[] = [];

		const displayedUnreadReplyIds = unreadReplies
			.filter((reply) => repliesToDisplay.some((displayedReply) => displayedReply.id === reply.id))
			.map((reply) => reply.id);

		idsToMarkAsRead.push(...displayedUnreadReplyIds);

		setCommentIdsByAnnotationToMarkAsRead({ parentCommentMarkerRef, commentIds: idsToMarkAsRead });
		unreadRepliesAlreadyMarkedAsRead.current = true;
	}

	const removedCommentData = removedCommentsAnnotationMap[parentCommentMarkerRef];
	const commentActionType = removedCommentData?.[2];

	// we don't want to show the reply container if the parent comment has been deleted or resolved, even though the parent comment is still technically active in the current view
	const displayNewReplyContainer =
		isParentCommentOpen &&
		isCurrentSelectedComment &&
		!(
			commentActionType === CommentActionType.DELETE_COMMENT ||
			commentActionType === CommentActionType.RESOLVE_COMMENT_THREAD
		);

	return (
		<>
			{allReplies.length > 0 && (
				<Box testId={`comment-thread-${parentComment.id}-replies`}>
					<RepliesOpener
						replies={allReplies}
						setRepliesToDisplay={setRepliesToDisplay}
						unreadReplies={unreadReplies}
						replyToDisplayCount={repliesToDisplay.length}
						setReplyView={setReplyView}
						currentReplyView={currentReplyView}
					/>
					<Stack as="ul" xcss={replyListStyles}>
						{repliesToDisplay.map(
							(reply) =>
								reply && (
									// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
									<Box
										xcss={commentBodyBoxStyles}
										as="li"
										key={reply.id}
										testId={`comment-thread-${parentComment.id}-replies-${reply.id}`}
										onMouseEnter={() => handleMouseEnter(reply.id)}
										onMouseLeave={handleMouseLeave}
									>
										<Comment
											comment={reply}
											supportedTopLevelActions={supportedTopLevelActions}
											pageInfo={pageInfo}
											pageMode={pageMode}
											isUnread={reply.isUnread}
											isHovered={hoveredCommentId === reply.id}
											parentCommentMarkerRef={parentCommentMarkerRef}
										/>
									</Box>
								),
						)}
					</Stack>
				</Box>
			)}
			{displayNewReplyContainer && (
				<NewReplyContainer
					mode="view"
					isCommentsPanel
					data-testid="comments-panel-new-reply-container"
				>
					<CommentEditor
						pageId={pageId}
						pageType={pageType}
						appearance="chromeless"
						EditorComponent={ChromelessEditor}
						onSaveComment={(adf, onSuccess) => {
							setReplyView(ViewReplyOptions.ALL);
							return onSaveComment({
								pageId,
								parentCommentId,
								adf,
								onSuccess,
								pageMode,
								editCommentQueryId,
								setCommentForEdit,
								createAnalyticsEvent,
								pageType,
								experienceTracker,
								createReplyFn,
								selectedAnnotation,
								addReplyToCommentThread,
								parentCommentMarkerRef,
								updateReadCommentsListState,
							});
						}}
						onContentChange={onChange}
						onEditorReady={() => {
							experienceTracker.succeed({
								name: REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
							});
						}}
						commentMode="reply"
						commentType="inline"
						spaceId=""
						showCancelButton
						useNewWarningModal
						hideWatchCheckbox
						expandEditor={false}
						pageMode="view"
						hasMediaUploadPermissions={hasMediaUploadPermissions}
						shouldDisplayCommentReplyPrompts={false}
						topLevelCommentId={parentComment.id}
						shouldWarnOnInternalNavigation
						commentThreadLength={allReplies.length + 1}
					/>
				</NewReplyContainer>
			)}
		</>
	);
};
