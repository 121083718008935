import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

export enum CurrentView {
	OPEN = 'OPEN',
	UNREAD = 'UNREAD',
	RESOLVED = 'RESOLVED',
}

export type CommentsPanelState = {
	currentView: CurrentView | undefined;
	currentlySelectedCommentMarkerRef: string | undefined;
	isCommentsPanelOpen: boolean;
	commentIdsByAnnotationToMarkAsRead: Record<string, Set<string>>; // maps annotation to set of comment IDs
};

const initialState: CommentsPanelState = {
	currentView: undefined,
	currentlySelectedCommentMarkerRef: undefined,
	isCommentsPanelOpen: false,
	commentIdsByAnnotationToMarkAsRead: {},
};

// Actions for managing the comments state in the comments panel
const actions = {
	setCurrentView:
		(view: CurrentView) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentView: view });
		},
	setCurrentlySelectedCommentMarkerRef:
		(markerRef: string | undefined) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentlySelectedCommentMarkerRef: markerRef });
		},
	setIsCommentsPanelOpen:
		(isOpen: boolean) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ isCommentsPanelOpen: isOpen });
		},
	setCommentIdsByAnnotationToMarkAsRead:
		({
			parentCommentMarkerRef,
			commentIds,
			clearList = false,
		}: {
			parentCommentMarkerRef?: string;
			commentIds?: string[];
			clearList?: boolean;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsPanelState>) => {
			const { commentIdsByAnnotationToMarkAsRead } = getState();
			if (clearList) {
				setState({ commentIdsByAnnotationToMarkAsRead: {} });
			} else {
				if (parentCommentMarkerRef && commentIds) {
					// Add the new comment IDs to the existing set for the specific parent
					const updatedCommentIds = { ...commentIdsByAnnotationToMarkAsRead };
					const existingIdsSet = updatedCommentIds[parentCommentMarkerRef] || new Set();
					commentIds.forEach((id) => existingIdsSet.add(id));
					// Update the map with the new set of IDs
					updatedCommentIds[parentCommentMarkerRef] = existingIdsSet;
					setState({ commentIdsByAnnotationToMarkAsRead: updatedCommentIds });
				}
			}
		},
};

const CommentsStore = createStore({
	initialState,
	actions,
	name: 'CommentsStore',
});

export const useCommentsPanel = createHook(CommentsStore);
