import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useCommentsData, useActiveInlineCommentsQuery } from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';

import { CommentsPanelList } from '../components/CommentsPanelList';
import { getUnreadCommentThreads } from '../helper/commentsPanelHelper';

const i18n = defineMessages({
	noUnreadCommentsText: {
		id: 'comments-panel.unread.comments.label',
		defaultMessage: 'You have no unread comments.',
		description: 'Text to display in Unread View when there are no unread comments for the page.',
	},
});

export const UnreadView = () => {
	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const [{ orderedActiveAnnotationIdList, inlineCommentsDataMap, removedCommentIdsMap }] =
		useCommentsData();

	const [contentId] = usePageContentId();
	const { formatMessage } = useIntl();

	const unreadAnnotationsToLoad = useMemo(() => {
		const unreadCommentsMarkerRefs = new Set([
			...unreadCommentsListState.map((unreadComment) => unreadComment.inlineMarkerRef),
		]);
		// Filter and map to get the annotation IDs that need to be loaded
		return orderedActiveAnnotationIdList
			.filter(
				(annotation) =>
					!annotation.isLoaded && unreadCommentsMarkerRefs.has(annotation.annotationId),
			)
			.map((annotation) => annotation.annotationId);
	}, [unreadCommentsListState, orderedActiveAnnotationIdList]);

	useActiveInlineCommentsQuery({
		pageId: contentId || '',
		markerRefList: unreadAnnotationsToLoad,
		skip: unreadAnnotationsToLoad.length === 0,
		orderedActiveAnnotationIdList,
		hasAlreadyFetchedInitialData: { current: true }, // we already fetched initial data at the parent level
	});

	// TODO needs improvement: getting the unread comment threads here when the unread view is opened means that
	// the user might be seeing unread comments that they previously read suddenly disappear
	// since we're marking comments as read only when the user changes views
	const commentThreads = useMemo(
		() =>
			getUnreadCommentThreads({
				numUnreadComments: unreadCommentsListState.length,
				inlineCommentsDataMap,
				orderedActiveAnnotationIdList,
				removedCommentIdsMap,
			}),
		[
			inlineCommentsDataMap,
			orderedActiveAnnotationIdList,
			unreadCommentsListState,
			removedCommentIdsMap,
		],
	);

	if (commentThreads.length === 0) {
		return <div>{formatMessage(i18n.noUnreadCommentsText)}</div>;
	}

	return (
		<CommentsPanelList
			commentThreads={commentThreads}
			supportedTopLevelActions={['edit', 'resolve', 'delete']}
		/>
	);
};
