import { createStore, createHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

import type {
	TopLevelComment as BaseComment,
	CommentReply as BaseReply,
} from '@confluence/inline-comments-queries';
import { getAnnotationPosition } from './helper/commentsDataHelper';

export interface AnnotationStatus {
	annotationId: string;
	isLoaded: boolean;
}

export interface ReplyData extends BaseReply {
	isUnread: boolean;
}

export interface CommentData extends BaseComment {
	isUnread: boolean;
	isOpen: boolean; // unresolved comment
	replies: ReplyData[];
}

export type CommentsDataState = {
	orderedActiveAnnotationIdList: AnnotationStatus[]; // ordered list of annotations on the page
	inlineCommentsDataMap: Record<string, CommentData>; // source of truth that maps annotation to a comment thread

	// handle removed comments in the current view
	removedCommentIdsMap: Record<number, [string, Set<string>]>; // map the annotation position to its annotation and list of comments so we can still display removed comment threads in the current view
	removedCommentsAnnotationMap: Record<string, [number, Set<string>, CommentActionType]>; // use as a reverse lookup into removedCommentIdsMap for removed comments to show warning message
};

export const initialState: CommentsDataState = {
	orderedActiveAnnotationIdList: [],
	inlineCommentsDataMap: {},
	removedCommentIdsMap: {},
	removedCommentsAnnotationMap: {},
};

export enum UnreadAction {
	READ,
	UNREAD,
}

export enum CommentActionType {
	DELETE_COMMENT = 'deleted',
	RESOLVE_COMMENT_THREAD = 'resolved',
}

export const actions = {
	setInlineCommentsDataMap:
		(newCommentsDataMap: Record<string, CommentData>) =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { orderedActiveAnnotationIdList } = getState();

			const parentCommentMarkerRefs = new Set(Object.keys(newCommentsDataMap));

			// Update the loaded status of annotations based on newCommentsDataMap
			const updatedAnnotationList = orderedActiveAnnotationIdList.map((annotation) => ({
				...annotation,
				isLoaded: parentCommentMarkerRefs.has(annotation.annotationId),
			}));

			setState({
				inlineCommentsDataMap: newCommentsDataMap,
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	setOrderedActiveAnnotationIdList:
		(newCommentsList: string[]) =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { orderedActiveAnnotationIdList } = getState();
			// Create a map of existing annotationId to their isLoaded states for quick lookup
			const existingAnnotationsMap = new Map(
				orderedActiveAnnotationIdList.map(({ annotationId, isLoaded }) => [annotationId, isLoaded]),
			);
			// Use a Set to ensure unique annotation IDs
			const uniqueCommentsSet = new Set(newCommentsList);
			// Map the unique comments set to the new state, preserving isLoaded where possible
			const updatedAnnotationList = Array.from(uniqueCommentsSet).map((annotationId) => ({
				annotationId,
				isLoaded: existingAnnotationsMap.get(annotationId) ?? false, // Preserve existing isLoaded or default to false
			}));
			setState({
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	updateUnreadStatus:
		(annotationWithCommentIDs: Record<string, Set<string>>, action: UnreadAction) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();

			const updatedMap = { ...inlineCommentsDataMap };

			// for each annotation and their comment IDs, update the unread status in the inlineCommentsDataMap map
			Object.entries(annotationWithCommentIDs).forEach(([annotationId, commentIdsToMark]) => {
				const commentData = updatedMap[annotationId];
				if (commentData) {
					const isParentCommentUnread = commentIdsToMark.has(commentData.id);
					updatedMap[annotationId] = {
						...commentData,
						isUnread: isParentCommentUnread ? action === UnreadAction.UNREAD : commentData.isUnread,
						replies: commentData.replies.map((reply) => {
							// Update the reply's unread status if its ID is included in commentIdsToMark
							if (commentIdsToMark.has(reply.id)) {
								return {
									...reply,
									isUnread: action === UnreadAction.UNREAD,
								};
							}
							return reply;
						}),
					};
				}
			});

			setState({ inlineCommentsDataMap: updatedMap });
		},
	addNewCommentThreads:
		(newCommentThreads: Record<string, CommentData>) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap, orderedActiveAnnotationIdList } = getState();

			const updatedCommentsDataMap = { ...inlineCommentsDataMap };

			// Add the comment threads
			for (const parentMarkerRef in newCommentThreads) {
				const newCommentThread = newCommentThreads[parentMarkerRef];
				updatedCommentsDataMap[parentMarkerRef] = newCommentThread;
			}

			// Update the loaded status of annotations
			const updatedAnnotationList = orderedActiveAnnotationIdList.map((annotation) => ({
				...annotation,
				isLoaded: Object.keys(newCommentThreads).includes(annotation.annotationId)
					? true
					: annotation.isLoaded,
			}));

			setState({
				inlineCommentsDataMap: updatedCommentsDataMap,
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	addReplyToCommentThread:
		(parentMarkerRef: string, reply: ReplyData) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();
			const parentCommentThread = inlineCommentsDataMap[parentMarkerRef];
			if (parentCommentThread) {
				// due to the nature of how we are sending multiple pubsub events are being sent for comment creation, we have to check for a duplicate before adding a reply
				const replyExists = parentCommentThread.replies.some(
					(existingReply) => existingReply.id === reply.id,
				);
				if (!replyExists) {
					parentCommentThread.replies.push(reply);
					setState({
						inlineCommentsDataMap: {
							...inlineCommentsDataMap,
							[parentMarkerRef]: parentCommentThread,
						},
					});
				}
			}
		},
	updateRemovedCommentIdsMap:
		({
			parentMarkerRef,
			commentId,
			commentActionType,
			clearList,
		}: {
			parentMarkerRef?: string;
			commentId?: string;
			commentActionType?: CommentActionType;
			clearList?: boolean;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { removedCommentIdsMap, orderedActiveAnnotationIdList } = getState();
			let updatedRemovedCommentIdsMap = { ...removedCommentIdsMap };

			let updatedReverseMap: Record<string, [number, Set<string>, CommentActionType]> = {};
			if (clearList) {
				// If clearList is true, reset the entire map
				setState({ removedCommentIdsMap: {}, removedCommentsAnnotationMap: {} });
			} else if (parentMarkerRef && commentId && commentActionType) {
				const annotationIdx = getAnnotationPosition(parentMarkerRef, orderedActiveAnnotationIdList);
				if (annotationIdx !== -1) {
					// Update the main map
					if (!updatedRemovedCommentIdsMap[annotationIdx]) {
						updatedRemovedCommentIdsMap[annotationIdx] = [parentMarkerRef, new Set()];
					}
					updatedRemovedCommentIdsMap[annotationIdx][1].add(commentId);

					// Update the reverse map
					if (!updatedReverseMap[parentMarkerRef]) {
						updatedReverseMap[parentMarkerRef] = [annotationIdx, new Set(), commentActionType];
					}
					updatedReverseMap[parentMarkerRef][1].add(commentId);
				}
				setState({
					removedCommentIdsMap: updatedRemovedCommentIdsMap,
					removedCommentsAnnotationMap: updatedReverseMap,
				});
			}
		},
	handleRemovingComments:
		({
			parentMarkerRef,
			commentId,
			action,
		}: {
			parentMarkerRef: string;
			commentId: string;
			action: CommentActionType;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();
			const parentCommentThread = inlineCommentsDataMap[parentMarkerRef];

			if (parentCommentThread) {
				if (
					action === CommentActionType.RESOLVE_COMMENT_THREAD &&
					parentCommentThread.id === commentId
				) {
					// If the action is to resolve the thread and the commentId matches the parent, remove the thread
					const updatedCommentsDataMap = { ...inlineCommentsDataMap };
					delete updatedCommentsDataMap[parentMarkerRef];
					setState({
						inlineCommentsDataMap: updatedCommentsDataMap,
					});
				} else if (action === CommentActionType.DELETE_COMMENT) {
					// If the action is to delete a reply, filter out the reply with the specified commentId
					const updatedReplies = parentCommentThread.replies.filter(
						(reply) => reply.id !== commentId,
					);
					// Update the parent comment thread with the filtered replies
					setState({
						inlineCommentsDataMap: {
							...inlineCommentsDataMap,
							[parentMarkerRef]: {
								...parentCommentThread,
								replies: updatedReplies,
							},
						},
					});
				}
			}
		},
};

export const CommentsDataStore = createStore({
	initialState,
	actions,
	name: 'CommentsDataStore',
});

export const useCommentsData = createHook(CommentsDataStore);
