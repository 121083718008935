import type { DataProxy } from 'apollo-cache';
import type { DocumentNode } from 'graphql';

import type { AnnotationInfo } from '@atlaskit/editor-plugins/annotation';

import type { MutationResult } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import type {
	CreateInlineReplyMutationType,
	InlineCommentQueryType,
	CommentsPanelQueryType,
} from '@confluence/inline-comments-queries';
import { CommentCreationLocation } from '@confluence/inline-comments-queries';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { REPLY_TO_INLINE_COMMENT_EXPERIENCE } from '@confluence/experience-tracker';
import { InlineCommentFramework } from '@confluence/inline-comments-common/entry-points/enum';
import { getLogger } from '@confluence/logger';
import { END } from '@confluence/navdex';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';

import { getTargetNodeType } from './analyticsUtils';

const logger = getLogger('inline-comments-common');

export const updateApolloCacheCallback =
	(
		actionType: 'delete' | 'create',
		queryVariables: any,
		queryToUpdate: DocumentNode,
		parentCommentId: string,
		commentId?: string,
	) =>
	(cache: DataProxy, result: MutationResult) => {
		// If the mutation fails, don't do anything
		if (!result) {
			return;
		}

		try {
			const response = cache.readQuery<CommentsPanelQueryType>({
				query: queryToUpdate,
				variables: queryVariables,
			});

			if (!response) {
				return;
			}

			// grab the parent comment
			type QueryType = CommentsPanelQueryType | InlineCommentQueryType;

			const data: QueryType = Object.assign({} as any, response);
			const parentComment = data?.comments?.nodes?.find(
				(comment) => comment?.id === parentCommentId,
			);

			if (parentComment) {
				if (actionType === 'delete') {
					// Remove the returned commentId from the cache and reset it
					const idxToRemove = parentComment.replies.findIndex((reply) => reply?.id === commentId);

					if (idxToRemove !== -1) {
						parentComment.replies.splice(idxToRemove, 1);
					}
				} else {
					const mutationResult = (result.data as CreateInlineReplyMutationType).replyInlineComment;
					// Add the new reply entry to the cache and reset it
					parentComment.replies.push(mutationResult);
				}

				cache.writeQuery({
					query: queryToUpdate,
					variables: queryVariables,
					data,
				});
			}
		} catch (err) {
			logger.error`An Error occurred when updating cache for ${actionType} reply - ${err}`;
		}
	};

export const getCommentCreationLocation = (isEditor: boolean, isLivePage: boolean) => {
	if (isLivePage) return CommentCreationLocation.LIVE;
	if (isEditor) return CommentCreationLocation.EDITOR;

	return CommentCreationLocation.RENDERER;
};

interface HandleCreateReplySuccessProps {
	onSuccess: any;
	editCommentQueryId: string | undefined;
	setCommentForEdit: any;
	data: any;
	createAnalyticsEvent: any;
	pageId: string | undefined;
	pageType: string;
	analyticsSource: string;
	commentId: string | undefined;
	pageMode: PageMode;
	selectedAnnotation: AnnotationInfo | undefined;
	getInlineNodeTypes: any;
	experienceTracker: ExperienceTrackerAPI;
	isEditor: boolean;
}

export const handleCreateReplySuccess = ({
	onSuccess,
	editCommentQueryId,
	setCommentForEdit,
	data,
	createAnalyticsEvent,
	pageId,
	pageType,
	analyticsSource,
	commentId,
	pageMode,
	selectedAnnotation,
	getInlineNodeTypes,
	experienceTracker,
	isEditor,
}: HandleCreateReplySuccessProps) => {
	onSuccess();
	if (editCommentQueryId) {
		setCommentForEdit('');
	}

	const replyCommentInfo = data?.replyInlineComment;

	const markerRef = replyCommentInfo?.location?.inlineMarkerRef;

	createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			action: 'created',
			actionSubject: 'comment',
			actionSubjectId: replyCommentInfo?.id, // The newly created comment ID
			objectType: pageType,
			objectId: pageId,
			source: analyticsSource,
			attributes: {
				commentType: 'inline',
				parentCommentId: commentId ?? null, // analytics event schema type expects string or null
				mode: pageMode,
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				navdexPointType: END,
				inlineNodeTypes:
					selectedAnnotation && getInlineNodeTypes
						? getInlineNodeTypes(selectedAnnotation.id)
						: undefined,
				targetNodeType: getTargetNodeType(markerRef, isEditor),
			},
		},
	}).fire();

	experienceTracker.succeed({
		name: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	});
};
