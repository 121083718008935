import { getApolloClient } from '@confluence/graphql';
import type { ExtractPresetAPI } from '@atlaskit/editor-common/preset';
import {
	ContentAnalyticsViewedComments,
	type TopLevelComment,
	type CommentReply,
} from '@confluence/inline-comments-queries';

import type {
	UnreadCommentQuery_comments_nodes as UnreadCommentNode,
	UnreadCommentQuery_comments_nodes_replies as UnreadCommentReply,
	UnreadCommentQuery_comments_nodes_location_InlineComment as UnreadLocationInlineComment,
} from '../__types__/UnreadCommentQuery';
import type { MarkerRefToCommentIdMap, UnreadInlineComment } from '../types/unreadCommentsTypes';

export interface CreateUnreadCommentListProps {
	allInlineComments: (UnreadCommentNode | null)[] | null | undefined;
	updateUnreadCommentsListState: (
		fn: (unreadCommentsListState: UnreadInlineComment[]) => UnreadInlineComment[],
	) => void;
	userId: string | null;
	pageId?: string;
	updateReadCommentsListState: (fn: (readComments: Set<string>) => Set<string>) => void;
	updateMarkerRefToCommentIdMap: (
		fn: (markerRefToCommentIdMap: MarkerRefToCommentIdMap) => MarkerRefToCommentIdMap,
	) => void;
	markerRefToCommentIdMap: MarkerRefToCommentIdMap;
	resetStates: () => void;
	editorApi?: ExtractPresetAPI<any>;
	pageMode?: 'view' | 'edit';
	annotationsInEditorDoc: Set<string>;
}

type ValidUnreadComment = {
	comment: UnreadCommentNode | UnreadCommentReply | TopLevelComment | CommentReply;
	viewedComments: Set<string>;
	userId: string;
	annotationsInEditorDoc: Set<string>;
	pageMode?: 'view' | 'edit';
};

export const isValidUnreadComment = ({
	comment,
	viewedComments,
	userId,
	annotationsInEditorDoc,
	pageMode = 'view',
}: ValidUnreadComment) => {
	const isUnread = !viewedComments.has(comment.id);
	const commentLocation = comment?.location as UnreadLocationInlineComment;
	const isUnresolved = !commentLocation?.inlineResolveProperties?.resolved;
	const isNotOwnComment = 'accountId' in comment.author && comment.author?.accountId !== userId;
	const createdLast30Days =
		new Date(comment.createdAt?.value) > new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
	const markerRefExists = commentLocation?.inlineMarkerRef;
	const isAnnotationInDoc =
		pageMode === 'edit' ? markerRefExists && annotationsInEditorDoc.has(markerRefExists) : true;
	return (
		isUnread &&
		isUnresolved &&
		isNotOwnComment &&
		createdLast30Days &&
		markerRefExists &&
		isAnnotationInDoc
	);
};

export const createUnreadCommentList = async ({
	allInlineComments,
	updateUnreadCommentsListState,
	userId,
	pageId,
	updateReadCommentsListState,
	updateMarkerRefToCommentIdMap,
	resetStates,
	pageMode = 'view',
	annotationsInEditorDoc,
}: CreateUnreadCommentListProps) => {
	const hasUnresolvedComments =
		allInlineComments &&
		allInlineComments.some(
			(comment) =>
				!(comment?.location as UnreadLocationInlineComment)?.inlineResolveProperties?.resolved,
		);

	if (hasUnresolvedComments && pageId) {
		try {
			const data = await getApolloClient().query({
				query: ContentAnalyticsViewedComments,
				variables: {
					contentId: pageId,
				},
				fetchPolicy: 'network-only',
			});

			const viewedComments: Set<string> = new Set(
				data?.data?.contentAnalyticsViewedComments?.commentIds,
			);
			updateReadCommentsListState(() => viewedComments);

			const unreadComments: UnreadInlineComment[] = [];
			const newMarkerRefMap: MarkerRefToCommentIdMap = {};
			allInlineComments.forEach((comment: UnreadCommentNode | null) => {
				if (!comment) {
					return;
				}

				const markerRef = (comment.location as UnreadLocationInlineComment)?.inlineMarkerRef;

				if (userId && markerRef) {
					if (
						isValidUnreadComment({
							comment,
							viewedComments,
							userId,
							annotationsInEditorDoc,
							pageMode,
						})
					) {
						unreadComments.push({
							id: comment.id,
							inlineMarkerRef: markerRef ?? '',
						});
					}

					if (comment.replies) {
						comment.replies.forEach((reply) => {
							if (reply) {
								if (
									isValidUnreadComment({
										comment: reply,
										viewedComments,
										userId,
										annotationsInEditorDoc,
										pageMode,
									})
								) {
									unreadComments.push({
										id: reply.id,
										inlineMarkerRef: markerRef ?? '',
									});
								}
								if (!newMarkerRefMap[markerRef]) {
									newMarkerRefMap[markerRef] = new Set([reply.id]);
								} else if (newMarkerRefMap[markerRef]) {
									newMarkerRefMap[markerRef].add(reply.id);
								}
							}
						});
					}
				}
			});

			updateMarkerRefToCommentIdMap((prevState) => {
				const mergedMarkerRefMap = { ...prevState };
				Object.keys(newMarkerRefMap).forEach((markerRef) => {
					if (!mergedMarkerRefMap[markerRef]) {
						mergedMarkerRefMap[markerRef] = newMarkerRefMap[markerRef];
					} else {
						mergedMarkerRefMap[markerRef] = new Set([
							...mergedMarkerRefMap[markerRef],
							...newMarkerRefMap[markerRef],
						]);
					}
				});
				return mergedMarkerRefMap;
			});
			updateUnreadCommentsListState(() => unreadComments);
			// Once we are done updating the unread list, need to reset the states.
			resetStates();
		} catch {
			// We don't want to show any unread count in the comment button if there's an error
			resetStates();
		}
	}
};
