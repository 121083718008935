import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl-next';

import { Stack, xcss } from '@atlaskit/primitives';

import { ReactionsProvider } from '@confluence/comment-context';
import { withErrorBoundary, Attribution } from '@confluence/error-boundary';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { CommentActionType, useCommentsData } from '@confluence/comments-data';

import { useCommentsPanel, CurrentView } from './hooks/useCommentsPanel';
import { type PillFilter, Pills } from './components/Pills';
import { useUnreadComments } from './hooks/useUnreadComments';
import { OpenView } from './views/OpenView';
import { UnreadView } from './views/UnreadView';
import { ResolvedView } from './views/ResolvedView';
import { panelWithoutHeaderHeight } from './CommentsPanelStyleConstants';

type CommentsPanelProps = {
	currentView: CurrentView;
	contentId: string;
};

const i18n = defineMessages({
	unread: {
		id: 'comments-panel.unread.view.label',
		defaultMessage: 'Unread',
		description: 'Label for the unread view pill',
	},
	open: {
		id: 'comments-panel.open.view.label',
		defaultMessage: 'Open',
		description: 'Label for the open view pill',
	},
	resolved: {
		id: 'comments-panel.resolved.view.label',
		defaultMessage: 'Resolved',
		description: 'Label for the resolved view pill',
	},
});

const pillOptions: PillFilter[] = [
	{ label: i18n.unread, name: CurrentView.UNREAD },
	{ label: i18n.open, name: CurrentView.OPEN },
	{ label: i18n.resolved, name: CurrentView.RESOLVED },
];

const commentsPanelViews: Record<CurrentView, (props: any) => JSX.Element> = {
	[CurrentView.UNREAD]: UnreadView,
	[CurrentView.OPEN]: OpenView,
	[CurrentView.RESOLVED]: ResolvedView,
};

const commentPanelContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	height: panelWithoutHeaderHeight,
});

const CommentsPanelComponent = withFlags((props: CommentsPanelProps & WithFlagsProps) => {
	const { currentView, contentId, flags } = props;

	const [{ currentView: userSelectedView }, { setCurrentView, setIsCommentsPanelOpen }] =
		useCommentsPanel();
	const [{ removedCommentIdsMap }, { updateRemovedCommentIdsMap, handleRemovingComments }] =
		useCommentsData();

	// use the view that the user selected, else use the view passed in by the parent
	const viewToShow = userSelectedView || currentView;

	// handle unread across the different views
	const { handleMarkCommentsAsRead } = useUnreadComments();

	const CommentView = commentsPanelViews[viewToShow];

	// TODO: this can be removed since we can now use the object sidebar to detect whether comments panel is open or not
	useEffect(() => {
		setIsCommentsPanelOpen(true);
		return () => {
			setIsCommentsPanelOpen(false);
		};
	}, [setIsCommentsPanelOpen]);

	return (
		<Stack xcss={commentPanelContainerStyles}>
			<Pills
				selectedPill={viewToShow}
				setPill={setCurrentView}
				pills={pillOptions}
				additionalActions={[
					// handle mark comments as read after view change
					handleMarkCommentsAsRead,

					// handle removed comments for every comment thread since we no longer want to display these after view change
					() =>
						Object.entries(removedCommentIdsMap).forEach(
							([_, [parentMarkerRef, commentIdsSet]]) => {
								commentIdsSet.forEach((commentId) => {
									handleRemovingComments({
										parentMarkerRef,
										commentId,
										action: CommentActionType.DELETE_COMMENT,
									});
								});
							},
						),
					() => updateRemovedCommentIdsMap({ clearList: true }),
				]}
			/>
			<ReactionsProvider contentId={contentId ?? ''}>
				<CommentView flags={flags} />
			</ReactionsProvider>
		</Stack>
	);
});

export const CommentsPanel = withErrorBoundary({
	attribution: Attribution.COLLABORATION,
})(CommentsPanelComponent);
