import { defineMessages } from 'react-intl-next';

import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';

import { CommentsPanelHeaderBadge } from './CommentsPanelHeaderBadge';
import { CommentsPanelWrapper } from './CommentsPanelWrapper';

export const i18n = defineMessages({
	commentsPanelHeaderLabel: {
		id: 'comments-panel.panel.header.label',
		defaultMessage: 'Comments',
		description: 'Label for the comments panel header',
	},
});

export const useShowCommentsPanel = () => {
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar, hideObjectSidebar }] =
		useObjectSidebar();

	const canClosePanel = () => {
		// TODO: Check if the content has changed before we allow the closing of the panel
		return true;
	};

	const showCommentsPanel = () => {
		showObjectSidebar(
			{
				id: PanelName.CommentsPanel,
				headerComponentElements: {
					headerLabel: i18n.commentsPanelHeaderLabel,
					HeaderAfterElement: CommentsPanelHeaderBadge,
				},
				BodyComponent: CommentsPanelWrapper,
				closeOptions: {
					canClosePanel,
				},
			},
			'push',
		);
	};

	// NOTE: Exposing the hide function for now while we have the CommentButton
	return {
		isCommentsPanelShown: isObjectSidebarShown && panel?.id === PanelName.CommentsPanel,
		showCommentsPanel,
		hideCommentsPanel: hideObjectSidebar,
	};
};
