import React from 'react';

import Badge from '@atlaskit/badge';

import { useCommentsData } from '@confluence/comments-data';

export const CommentsPanelHeaderBadge = () => {
	const [{ orderedActiveAnnotationIdList }] = useCommentsData();

	return <Badge>{orderedActiveAnnotationIdList.length}</Badge>;
};
