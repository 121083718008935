import { COMMENT_BATCH_SIZE } from '@confluence/comments-data';
import type { AnnotationStatus, CommentData } from '@confluence/comments-data';

export const scrollCommentsPanel = ({
	containerId,
	commentMarkerRef,
}: {
	containerId: string;
	commentMarkerRef: string;
}) => {
	const container = document.querySelector(`[data-testid='${containerId}']`);
	if (container) {
		const element = document.querySelector(`[data-testid='${commentMarkerRef}']`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}
};

export const getAnnotationsToLoad = (orderedActiveAnnotationIdList: AnnotationStatus[]) => {
	return orderedActiveAnnotationIdList
		.slice(0, Math.min(orderedActiveAnnotationIdList.length, COMMENT_BATCH_SIZE))
		.filter((item) => !item.isLoaded) // only get the ones that are not loaded yet
		.map((item) => item.annotationId);
};

// organize a list of open comment threads that we have data for
export const getOpenCommentThreads = ({
	inlineCommentsDataMap,
	orderedActiveAnnotationIdList,
	removedCommentIdsMap,
}: {
	inlineCommentsDataMap: Record<string, CommentData>;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedCommentIdsMap: Record<number, [string, Set<string>]>;
}) => {
	const openCommentThreads: CommentData[] = [];
	const alreadyAddedCommentThread = new Set<string>();
	const maxLength = Math.max(
		orderedActiveAnnotationIdList.length,
		...Object.keys(removedCommentIdsMap).map(Number),
	);
	for (let i = 0; i < maxLength; i++) {
		// Add deleted comment thread if it exists at this position
		if (i in removedCommentIdsMap) {
			const [deletedAnnotationId] = removedCommentIdsMap[i];
			const deletedThread = inlineCommentsDataMap[deletedAnnotationId];

			// alreadyAddedCommentThread will help prevent duplicate comment threads by using the deleted annotation ID and not the deleted comment ID
			if (deletedThread && !alreadyAddedCommentThread.has(deletedAnnotationId)) {
				openCommentThreads.push(deletedThread);
				alreadyAddedCommentThread.add(deletedAnnotationId);
			}
		}
		// Add active comment thread if it hasn't been added already
		if (i < orderedActiveAnnotationIdList.length) {
			const annotation = orderedActiveAnnotationIdList[i];
			const annotationId = annotation.annotationId;
			const commentThread = inlineCommentsDataMap[annotationId];
			if (commentThread && !alreadyAddedCommentThread.has(annotationId)) {
				openCommentThreads.push(commentThread);
				alreadyAddedCommentThread.add(annotationId);
			}
		}
	}
	return openCommentThreads;
};

// organize a list of unread comment threads that we have data for
export const getUnreadCommentThreads = ({
	numUnreadComments,
	inlineCommentsDataMap,
	orderedActiveAnnotationIdList,
	removedCommentIdsMap,
}: {
	numUnreadComments: number;
	inlineCommentsDataMap: Record<string, CommentData>;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedCommentIdsMap: Record<number, [string, Set<string>]>;
}) => {
	const unreadCommentThreads: CommentData[] = [];
	const alreadyAddedCommentThread = new Set<string>();
	if (numUnreadComments > 0 && Object.keys(inlineCommentsDataMap).length > 0) {
		const maxLength = Math.max(
			orderedActiveAnnotationIdList.length,
			...Object.keys(removedCommentIdsMap).map(Number),
		);
		for (let i = 0; i < maxLength; i++) {
			// Add unread deleted comment threads if they exist at this position
			if (i in removedCommentIdsMap) {
				const [deletedAnnotationId] = removedCommentIdsMap[i];
				const deletedThread = inlineCommentsDataMap[deletedAnnotationId];
				if (deletedThread && !alreadyAddedCommentThread.has(deletedAnnotationId)) {
					if (deletedThread.isUnread || deletedThread.replies.some((reply) => reply.isUnread)) {
						unreadCommentThreads.push(deletedThread);
						alreadyAddedCommentThread.add(deletedAnnotationId);
					}
				}
			}
			// Add unread active comment threads if available
			if (i < orderedActiveAnnotationIdList.length) {
				const annotation = orderedActiveAnnotationIdList[i];
				const annotationId = annotation.annotationId;
				const commentThread = inlineCommentsDataMap[annotationId];
				if (commentThread && !alreadyAddedCommentThread.has(annotationId)) {
					if (commentThread.isUnread || commentThread.replies.some((reply) => reply.isUnread)) {
						unreadCommentThreads.push(commentThread);
						alreadyAddedCommentThread.add(annotationId);
					}
				}
			}
		}
	}
	return unreadCommentThreads;
};
