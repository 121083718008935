import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';
import { xcss, Text, Box } from '@atlaskit/primitives';

import {
	getEditorAnnotationEventEmitter,
	getRendererAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { useCommentsData } from '@confluence/comments-data';
import { CommentBody, EditComment } from '@confluence/inline-comments-common';
import {
	handleResolveSuccess,
	handleMutationFailure,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { usePageContentId } from '@confluence/page-context';
import {
	CommentsPanelQuery,
	InlineCommentsQuery,
	ResolveInlineCommentMutation,
	ReopenInlineCommentMutation,
} from '@confluence/inline-comments-queries';
import type {
	CommentsPanelQueryType,
	InlineCommentAuthorUser,
	TopLevelComment,
	CommentReply,
	ReopenInlineCommentMutationType,
	ReopenInlineCommentMutationVariables,
	CommentLocation,
} from '@confluence/inline-comments-queries';
import { useInlineComments } from '@confluence/inline-comments-hooks';
import {
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { PageInfoNode } from '@confluence/page-info';
import type { FlagsStateContainer } from '@confluence/flags';

import { CurrentView, useCommentsPanel } from '../hooks/useCommentsPanel';

type CommentProps = {
	comment: TopLevelComment | CommentReply;
	supportedTopLevelActions: CommentAction[];
	pageMode: PageMode;
	pageInfo: PageInfoNode | null;
	isUnread?: boolean;
	isHovered?: boolean;
	parentCommentMarkerRef: string;
	isResolved?: boolean;
	flags?: FlagsStateContainer;
};

const i18n = defineMessages({
	commentReopenedFlagTitle: {
		id: 'comments-panel.comment.reopened.flag.title',
		defaultMessage: 'Comment Reopened',
		description:
			'Title to display in Flag to inform user when the comment was successfully reopened.',
	},
	goToCommentCTA: {
		id: 'comments-panel.comment.reopened.flag.cta.gotocomment',
		defaultMessage: 'Go to Comment',
		description:
			'CTA button text displayed in Flag for users to open the comment that was just reopened.',
	},
	undoCTA: {
		id: 'comments-panel.comment.reopened.flag.cta.undo',
		defaultMessage: 'Undo',
		description: 'CTA button text displayed in Flag for users to undo the comment reopened action.',
	},
	commentsPanelCommentActionLabel: {
		id: 'comments-panel.panel.action.label',
		defaultMessage: `This comment has been {action}`,
		description: 'Label for the comments panel resolved/deleted message',
	},
});

const warningMessageStyle = xcss({
	display: 'flex',
	fontStyle: 'italic',
	padding: 'space.150',
});

// this component can be used for parent comment and a reply
export const Comment = ({
	comment,
	supportedTopLevelActions,
	pageMode,
	pageInfo,
	isUnread = false,
	isHovered,
	parentCommentMarkerRef,
	isResolved = false,
	flags,
}: CommentProps) => {
	const [editCommentId, setCommentForEdit] = useState('');

	const experienceTracker = useContext(ExperienceTrackerContext);
	const [contentId] = usePageContentId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { removeUnresolvedInlineComment, addUnresolvedInlineComment }] = useInlineComments();
	const [{ inlineCommentsDataMap }, { addNewCommentThreads }] = useCommentsData();
	const [, { setCurrentView, setCurrentlySelectedCommentMarkerRef }] = useCommentsPanel();
	const { formatMessage } = useIntl();

	const [{ removedCommentsAnnotationMap }] = useCommentsData();

	const [resolveInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolveInlineCommentMutation,
	);
	const [reopenInlineCommentFn] = useMutation<
		ReopenInlineCommentMutationType,
		ReopenInlineCommentMutationVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	>(ReopenInlineCommentMutation, {
		refetchQueries:
			// Copied from the existing dialog flow to support standalone experience
			pageMode === PageMode.VIEW
				? [{ query: InlineCommentsQuery, variables: { pageId: contentId } }]
				: [],
	});

	const eventEmitter =
		pageMode === PageMode.VIEW
			? getRendererAnnotationEventEmitter()
			: getEditorAnnotationEventEmitter();

	// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
	const pageId: string = contentId;

	const userId = (comment?.author as InlineCommentAuthorUser)?.accountId;
	const topCommentUserAvatar = comment?.author?.profilePicture?.path;
	const topCommentDisplayName = comment?.author?.displayName ?? 'Anonymous';
	const commentId = comment?.id;
	const date = comment?.version?.when;
	const permissions = comment?.permissions;
	const content = comment?.body?.value;
	const permissionType = comment?.author?.permissionType ?? undefined;
	const isReply = !!comment?.parentId;
	const numReplies = (comment as TopLevelComment)?.replies?.length || 0;
	const commentUrl = comment?.links.webui ?? undefined;

	const resolveProperties =
		(comment?.location as CommentLocation)?.inlineResolveProperties || undefined;
	const annotatedText = (comment?.location as CommentLocation)?.inlineText || undefined;

	const handleDeleteComment = () => {
		// NOTE: The DELETE experience is started in CommentActions.tsx
	};

	const handleEditComment = () => {
		setCommentForEdit(commentId);
	};

	const exitCommentEditor = () => {
		setCommentForEdit('');
	};

	const handleReopenComment = () => {
		// NOTE: The reopen experience is started in CommentActions.tsx
		reopenInlineCommentFn({
			variables: { commentId },
		})
			.then(() => {
				// Update the list of active annotationIds
				addUnresolvedInlineComment(parentCommentMarkerRef, pageMode);

				// Emit the event to editor/renderer to reactivate the annotationId
				if (pageMode === PageMode.VIEW) {
					const emitter = getRendererAnnotationEventEmitter();
					emitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
						[parentCommentMarkerRef]: {
							id: parentCommentMarkerRef,
							annotationType: AnnotationTypes.INLINE_COMMENT,
							state: AnnotationMarkStates.ACTIVE,
						},
					});
				} else {
					const emitter = getEditorAnnotationEventEmitter();
					emitter.emit('unresolve', parentCommentMarkerRef);
				}

				// Update the state for panel experience
				const commentInMap = inlineCommentsDataMap[parentCommentMarkerRef];
				commentInMap &&
					addNewCommentThreads({
						[parentCommentMarkerRef]: {
							...commentInMap,
							isOpen: true,
						},
					});

				// Show the flag
				flags?.showCustomFlag({
					id: commentId,
					title: formatMessage(i18n.commentReopenedFlagTitle),
					isAutoDismiss: true,
					actions: [
						{
							content: formatMessage(i18n.goToCommentCTA),
							onClick: () => {
								setCurrentView(CurrentView.OPEN);
								setCurrentlySelectedCommentMarkerRef(parentCommentMarkerRef);
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
						{
							content: formatMessage(i18n.undoCTA),
							onClick: () => {
								handleResolveComment();
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
					],
				});
			})
			.catch(() => {
				// TODO: get error flag copy from design
			});
	};

	const handleResolveComment = () => {
		// NOTE: The RESOLVED experience is started in CommentActions.tsx
		resolveInlineCommentFn({
			variables: { commentId, resolved: true },
			update: (cache) => {
				// readQuery can still return null which complicates TS lint errors with optional chaining
				// once we move to v3 of the apollo client we can move to cache.modify
				const dataProxy = cache.readQuery<CommentsPanelQueryType>({
					query: CommentsPanelQuery,
					variables: {
						pageId,
					},
				});

				const commentsToWrite = Object.assign({}, dataProxy?.comments);
				let totalCount = commentsToWrite.totalCount || 0;
				const commentsList = commentsToWrite.nodes?.filter((n) => n !== null) as TopLevelComment[];

				const idxToRemove = commentsList.findIndex((c) => c.id === commentId);

				// Remove the newly resolved comment from the list if we can find it
				if (idxToRemove !== -1) {
					commentsList.splice(idxToRemove, 1);
					totalCount--;
				}

				cache.writeQuery<CommentsPanelQueryType>({
					query: CommentsPanelQuery,
					variables: { pageId },
					data: {
						comments: {
							nodes: commentsList,
							totalCount,
						},
					},
				});
			},
		})
			.then(() => {
				// Update the state for panel experience
				const commentInMap = inlineCommentsDataMap[parentCommentMarkerRef];
				commentInMap &&
					addNewCommentThreads({
						[parentCommentMarkerRef]: {
							...commentInMap,
							isOpen: false,
						},
					});

				handleResolveSuccess({
					commentId,
					parentCommentMarkerRef,
					pageId,
					pageMode,
					eventEmitter,
					source: 'commentsPanel',
					removeUnresolvedInlineComment,
					createAnalyticsEvent,
					//getInlineNodeTypes  // TODO: Figure out how to get this info
				});

				experienceTracker.succeed({
					name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: RESOLVE_INLINE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const pageType = pageInfo?.type ?? '';

	// Editor setup
	const spaceId = pageInfo?.space?.id ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	const removedCommentData = removedCommentsAnnotationMap[parentCommentMarkerRef];
	const isCommentRemoved = !!removedCommentData?.[1]?.has(commentId);
	const commentActionType = removedCommentData?.[2];
	const isParentComment = !isReply;

	return commentId === editCommentId ? (
		<EditComment
			pageId={pageId}
			pageType={pageType}
			annotationId={parentCommentMarkerRef}
			commentId={commentId}
			spaceId={spaceId}
			content={content ?? ''}
			isReply={isReply}
			displayCommentInViewMode={exitCommentEditor}
			avatarUrl={topCommentUserAvatar ?? ''}
			displayName={topCommentDisplayName}
			mode={pageMode === PageMode.VIEW ? 'view' : 'edit'}
			hasMediaUploadPermissions={hasMediaUploadPermissions}
			isCommentsPanel
		/>
	) : (
		<>
			{(isParentComment || (isReply && !isCommentRemoved)) && (
				<CommentBody
					mode="view"
					userId={userId}
					avatarUrl={topCommentUserAvatar}
					displayName={topCommentDisplayName}
					date={date ?? ''}
					dateUrl={commentUrl ?? ''}
					pageId={pageId}
					pageType={pageType}
					commentId={commentId ?? ''}
					isReply={isReply}
					permissions={permissions}
					content={content ?? ''}
					permissionType={permissionType ?? undefined}
					supportedActions={supportedTopLevelActions}
					deleteComment={handleDeleteComment}
					editComment={handleEditComment}
					resolveComment={handleResolveComment}
					numReplies={numReplies}
					isUnread={isUnread}
					isCommentActive
					isCommentsPanel
					isHovered={isHovered}
					isResolved={isResolved}
					resolveProperties={resolveProperties}
					annotationId={parentCommentMarkerRef}
					annotatedText={annotatedText}
					reopenComment={handleReopenComment}
				/>
			)}
			{isCommentRemoved && (
				<Box xcss={warningMessageStyle}>
					<Text weight="bold">
						{formatMessage(i18n.commentsPanelCommentActionLabel, {
							action: commentActionType,
						})}
					</Text>
				</Box>
			)}
		</>
	);
};
