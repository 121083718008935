import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import { token } from '@atlaskit/tokens';

import type { ReplyData } from '@confluence/comments-data';
import { ViewReplyOptions } from '../helper/commentThreadHelper';

const replyOpenerStyles = xcss({
	paddingLeft: 'space.400',
	cursor: 'pointer',
	userSelect: 'none',
	display: 'flex',
	alignItems: 'center',
});

const unreadIndicatorStyle = xcss({
	paddingLeft: 'space.100',
	display: 'flex',
	alignItems: 'center',
});

const defaultDisplayCount = 3;

const i18n = defineMessages({
	commentsPanelHideRepliesText: {
		id: 'comments-panel.comment-thread.hide.replies',
		defaultMessage: 'Hide replies',
		description: 'Text for hiding replies',
	},
	commentsPanelShowMoreRepliesText: {
		id: 'comments-panel.comment-thread.show.more.replies',
		defaultMessage: `Show {numOfMoreRepliesToDisplay, plural, one {# more reply} other {# more replies}}`,
		description: 'Text for showing more replies',
	},
});

export const RepliesOpener = ({
	replies,
	setRepliesToDisplay,
	unreadReplies,
	replyToDisplayCount,
	setReplyView,
	currentReplyView,
}: {
	replies: ReplyData[];
	setRepliesToDisplay: React.Dispatch<React.SetStateAction<ReplyData[]>>;
	unreadReplies: ReplyData[];
	replyToDisplayCount: number;
	setReplyView: (option: ViewReplyOptions) => void;
	currentReplyView: ViewReplyOptions;
}) => {
	const { formatMessage } = useIntl();

	const numReplies = replies.length;

	const handleReplyViewClick = () => {
		// if the current view is unread or it's hidden, then show all
		if (
			(replies.length > defaultDisplayCount && currentReplyView === ViewReplyOptions.DEFAULT) ||
			currentReplyView === ViewReplyOptions.HIDDEN
		) {
			setReplyView(ViewReplyOptions.ALL);
			setRepliesToDisplay(replies);
		}

		// if it's already showing all, then hide
		if (currentReplyView === ViewReplyOptions.ALL) {
			setReplyView(ViewReplyOptions.HIDDEN);
			setRepliesToDisplay([]);
		}
	};

	useEffect(() => {
		// if there are unread replies, then we want to show up to 3
		if (unreadReplies.length > 0) {
			const initialReplies = unreadReplies.slice(
				-Math.min(unreadReplies.length, defaultDisplayCount),
			);
			setRepliesToDisplay(initialReplies);

			if (unreadReplies.length > defaultDisplayCount || replies.length > unreadReplies.length) {
				setReplyView(ViewReplyOptions.DEFAULT);
			} else {
				setReplyView(ViewReplyOptions.ALL);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to set the display view here on first open
	}, []);

	// This useEffect makes comment replies show up immediately in the comments panel when saved
	useEffect(() => {
		if (currentReplyView === ViewReplyOptions.ALL) {
			setRepliesToDisplay(replies);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numReplies]);

	const numOfMoreRepliesToDisplay =
		currentReplyView === ViewReplyOptions.DEFAULT
			? replies.length - replyToDisplayCount
			: replies.length;
	const showMoreReplies =
		currentReplyView === ViewReplyOptions.HIDDEN ||
		(currentReplyView === ViewReplyOptions.DEFAULT && replies.length > defaultDisplayCount);
	const hideReplies = currentReplyView === ViewReplyOptions.ALL;

	return (
		<Box xcss={replyOpenerStyles} onClick={handleReplyViewClick}>
			{showMoreReplies && (
				<>
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
					<ChevronDownIcon size="large" label="show-more-replies-button" />
					{formatMessage(i18n.commentsPanelShowMoreRepliesText, { numOfMoreRepliesToDisplay })}
					{unreadReplies.length > defaultDisplayCount && (
						<Box
							xcss={unreadIndicatorStyle}
							testId="comments-panel-unread-comment-replies-indicator"
						>
							{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
							<PresenceActiveIcon
								size="small"
								primaryColor={token('color.icon.brand', '#0052CC')}
								label=""
							/>
						</Box>
					)}
				</>
			)}

			{hideReplies && (
				<>
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
					<ChevronUpIcon size="large" label="hide-replies-button" />
					{formatMessage(i18n.commentsPanelHideRepliesText)}
				</>
			)}
		</Box>
	);
};
