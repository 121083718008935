import React, { useMemo } from 'react';

import { inlineCommentGifContent } from '@confluence/inline-comments-common';
import { useCommentsData, useActiveInlineCommentsQuery } from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';

import { CommentsPanelList } from '../components/CommentsPanelList';
import { getOpenCommentThreads, getAnnotationsToLoad } from '../helper/commentsPanelHelper';

export const OpenView = () => {
	const [{ orderedActiveAnnotationIdList, inlineCommentsDataMap, removedCommentIdsMap }] =
		useCommentsData();

	const commentThreads = useMemo(
		() =>
			orderedActiveAnnotationIdList.length > 0
				? getOpenCommentThreads({
						inlineCommentsDataMap,
						orderedActiveAnnotationIdList,
						removedCommentIdsMap,
					})
				: [],
		[orderedActiveAnnotationIdList, inlineCommentsDataMap, removedCommentIdsMap],
	);

	const [contentId] = usePageContentId();

	const annotationsToLoad = getAnnotationsToLoad(orderedActiveAnnotationIdList);
	const { isLoading: _isLoading, error } = useActiveInlineCommentsQuery({
		pageId: contentId || '',
		markerRefList: annotationsToLoad,
		// skip entirely if no unresolved comments on the page or if there are no comments to load anymore
		skip: annotationsToLoad.length === 0,
		orderedActiveAnnotationIdList,
		hasAlreadyFetchedInitialData: { current: true }, // we already fetched initial data at the parent level
	});

	// TODO: we only want to show this on intial render, for new comments, we don't want to show this loading state
	// if (isLoading) {
	// 	return <div>Loading comments...</div>;
	// }

	if (error) {
		return <div>{error.message}</div>;
	}

	if (commentThreads.length === 0) {
		return inlineCommentGifContent();
	}

	return (
		<CommentsPanelList
			commentThreads={commentThreads}
			supportedTopLevelActions={['edit', 'resolve', 'delete']}
		/>
	);
};
